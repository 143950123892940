<template>
  <section class="employee-document-authorize" v-loading="documentDataLoading">
    <div class="inner-navbar">
      <el-row type="flex" align="middle" justify="center">
        <el-col
          :xl="{ span: 7 }"
          :lg="{ span: 8 }"
          :md="{ span: 12 }"
          :sm="{ span: 16 }"
        >
          <div class="text-center mt-2">
            <img src="@/assets/img/logo.png" alt="Logo" width="220" />
          </div>
          <el-card shadow="never" class="mt-2">
            <h2 class="font-weight-normal blue-2 text-center pt-05">
              Access Document
            </h2>
            <div>
              <div class="mt-05" shadow="never">
                <el-form>
                  <el-form-item
                    class="mb-05"
                    label="Enter the password recieve at your e-mail id"
                  >
                    <el-input
                      v-model="password"
                      placeholder="Ex: 23DR2C"
                    ></el-input>
                  </el-form-item>
                  <p
                    class="error"
                    v-if="
                      getAuthorizedRepresentativeVerifyPasswordErrors &&
                      getAuthorizedRepresentativeVerifyPasswordErrors.password
                    "
                  >
                    {{
                      getAuthorizedRepresentativeVerifyPasswordErrors.password
                    }}
                  </p>
                </el-form>
              </div>
              <p
                class="error"
                v-if="
                  getAuthorizedRepresentativeVerifyPasswordErrors &&
                  getAuthorizedRepresentativeVerifyPasswordErrors.critical_error
                "
              >
                {{
                  getAuthorizedRepresentativeVerifyPasswordErrors.critical_error
                }}
              </p>
              <p
                class="error"
                v-if="
                  getAuthorizedRepresentativeVerifyPasswordErrors &&
                  getAuthorizedRepresentativeVerifyPasswordErrors.user_id
                "
              >
                Invalid Link
              </p>
              <p
                class="error"
                v-if="
                  getAuthorizedRepresentativeVerifyPasswordErrors &&
                  getAuthorizedRepresentativeVerifyPasswordErrors.employee_document_id
                "
              >
                Invalid Link
              </p>

              <p class="error" v-if="invalidLink">Invalid Link</p>
              <div class="text-center mt-1">
                <el-button
                  type="success"
                  round
                  @click="verifyPassword"
                  :disabled="invalidLink"
                >
                  Verify
                  <i class="el-icon-right"></i>
                </el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <p class="copyright-text text-grey-sm text-center mt-2">
        All Rights Reserved 2020 © NimbleHRMS
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmpCustDocTypePwdVerification",
  components: {},
  computed: {
    ...mapGetters("auth", [
      "getAuthenticationStatus",
      "getAuthorizedRepresentativeVerifyPasswordErrors",
    ]),
  },
  created() {},
  data() {
    return {
      allStandardDocuments: [
        {
          name: "I9 Form",
          description: "I-9, Employment Eligibility Verification",
          value: "I9_FORM",
          slug: "i9-form",
          type: "STANDARD",
          updated_at: "",
          updated_by: {},
        },
      ],
      standardDocumentSlug: "",
      employeeDocumentId: "",
      workflowStepId: "",
      userId: "",
      password: "",
      documentDataLoading: false,
      invalidLink: false,
    };
  },
  async mounted() {
    this.$store.commit("auth/setAuthorizedRepresentativeVerifyPasswordErrors");
    this.documentDataLoading = true;
    this.setData();

    this.checkData();

    this.documentDataLoading = false;
  },
  methods: {
    setData() {
      this.employeeDocumentId = this.$route.params.employee_document_id;
      this.userId = this.$route.query.user_id;
      this.standardDocumentSlug = this.$route.params.document_slug;

      this.standardDocument = this.allStandardDocuments.find(
        (x) => x.slug == this.standardDocumentSlug
      );
      this.workflowName = this.$route.query.workflow_name || "Company Document";
    },
    async verifyPassword() {
      let params = {
        password: this.password,
        user_id: this.userId,
        employee_document_id: this.employeeDocumentId,
      };
      await this.$store.dispatch(
        "auth/verifyAuthorizedRepresentativePassword",
        params
      );

      if (this.getAuthenticationStatus) {
        this.$router.push({
          name: "employee-custom-doc-type-document-pp",
          params: {
            employee_document_id: this.employeeDocumentId,
          },
        });
      }
    },
    checkData() {
      if (!this.employeeDocumentId || !this.userId) {
        this.invalidLink = true;
      }
    },
  },
  
};
</script>

<style lang="scss" scoped>
.employee-document-authorize {
  position: relative;
}
.copyright-text {
  position: absolute;
  bottom: -4em;
  left: 0;
  right: 0;
}
.error {
  color: #f754a2;
}
</style>